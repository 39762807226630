import React from 'react';
import Carousel from 'react-material-ui-carousel'
import Item from './Item'
import causel_1 from '../../assets/img/slide1.jpg'
import causel_2 from '../../assets/img/slide2.jpg'
import causel_3 from '../../assets/img/slide3.jpg'
import causel_4 from '../../assets/img/slide5.jpg'


function CarouselComponent(props)
{
    const slider = [
        {
            id: 1,
            image: causel_1,
            title: "Title here1"
        },
        {
            id: 2,
            image: causel_2,
            title: "Title here2"
        },
        {
            id: 3,
            image: causel_3,
            title: "Title here3"
        },
        {
            id: 4,
            image: causel_4,
            title: "Title here4"
        },
    ]
    

    return (
        <Carousel
        navButtonsAlwaysVisible={
            true
        }
        animation={
            'slide'
        }>
            {
                slider.map( (item) => <Item key={item?.id} item={item} /> )
            }
        </Carousel>
    )
}

export default CarouselComponent;
