import React from 'react'
import Carousel from '../../components/carousel/Carousel'
import About from '../about/About'
import Service from '../services/Service'
import Clients from '../../components/clients/Clients'
import Overview from '../../components/overview/Overview'
import UpArrow from '../../components/uparrow/UpArrow'

const Home = () => {
  return (
    <div>
      <Carousel/>
      <About/>
      <Service/>
      {/* <Clients/> */}
      {/* <Overview/> */}
      <UpArrow/>
    </div>
  )
}

export default Home
