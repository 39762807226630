import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Header from './components/header/Header';
import Home from './pages/home/Home';
import PageNotFound from './pages/404/PageNotFound';
import Footer from './components/footer/Footer';
import Service from './pages/services/Service';
import About from './pages/about/About';
import Contact from './pages/contact/Contact';

function App() {
  // react router dom
  return (
    <BrowserRouter>
    <Header/>
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/service" element={<Service/>} />
      <Route path="/about" element={<About/>} />
      <Route path="/contact" element={<Contact/>} />
      <Route path="*" element={<PageNotFound/>} /> 
    </Routes>
    <Footer/>
    </BrowserRouter>
  );
}

export default App;
