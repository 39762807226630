import React, { useState } from "react";
import "./Header.css";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import amey_Logo5 from '../../assets/img/amey_Logo5.png'
import amey_Logo6 from '../../assets/img/amey_Logo6.png'

const Header = () => {
  const [showMediaIcons, setShowMediaIcons] = useState(false);
  const [onScrollImg, setOnScrollImg] = useState(false);

// var logo = document.querySelector('.nav-logo span')
// var nav_hover = document.querySelector('.nav-menu')
window.onscroll = function () { 
    // "use strict";
  var myNav = document.querySelector('.main-nav');
    if (document.body.scrollTop >= 100 || document.documentElement.scrollTop >= 100 ) {
        myNav?.classList.add("nav__scrolled");
        setOnScrollImg(true)
    } 
    else {
        myNav?.classList.remove("nav__scrolled");
        setOnScrollImg(false)
    }
};

  return (
    <>
      <nav className="main-nav" >
        {/* 1st logo part  */}
        <div className={showMediaIcons ? "nav_container" : "" } onClick={() => setShowMediaIcons(!showMediaIcons)} ></div>
        <div className="logo">
          <h2 className="nav__header">
            {/* <span>A</span>mey
            <span>A</span>pp */}
            {/* AMEY APP */}    
            <a href="#" alt="logo">        
            <img className="w-32 p-0" src={onScrollImg ? amey_Logo5 : amey_Logo6} alt="Logo" />          
            </a>
          </h2>
        </div>

        {/* 2nd menu part  */}
        <div
          className={
            showMediaIcons ? "menu-link mobile-menu-link" : "menu-link"
          }  onClick={() => setShowMediaIcons(false)}>
          <ul>
            <li>
              <a href='/#' className="nav__header">Home</a>
            </li>
            <li>
            <a href='/#about' className="nav__header">About</a>
            </li>
            <li>
            <a href='/#services' className="nav__header">service</a>
            </li>           
            <li>
            <a href='/#contact' className="nav__header">Contact</a>
            </li>           
            {/* <li>
            <a href='/#clients' className="nav__header">Clients</a>
            </li>            */}
          </ul>
        </div>

        {/* 3rd social media links */}
        <div className="social-media">      

          {/* hamburget menu start  */}
          <div className="hamburger-menu">
            <button onClick={() => setShowMediaIcons(!showMediaIcons)}>
              {showMediaIcons ? <CloseIcon className="hamburger__icon"/> : <MenuIcon className="hamburger__icon"/> }
            </button>
          </div>
        </div>
      </nav>

    </>
  );
};


export default Header
