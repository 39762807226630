import React from 'react'
import './UpArrow.css'
import up_arrow from '../../assets/img/uparrow.svg'

const UpArrow = () => {

//   window.onscroll = function () { 
//     // "use strict";
//   var myGoUp = document.querySelector('.go-up');
//     if (document.body.scrollTop <= 100 || document.documentElement.scrollTop <= 100 ) {
//       myGoUp?.classList.add("goup__scrolled");
//     } 
//     else {
//       myGoUp?.classList.remove("goup__scrolled");
//     }
// };
  return (
    <a href="#" id="up" className="go-up">
        <img src={up_arrow} alt=""/>
    </a>
  )
}

export default UpArrow
