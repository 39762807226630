import React, { useRef, useEffect, useState } from "react";
import "./Service.css";

const Service = () => {
  const service_data = [
    {
      id: 1,
      header: "Software development",
      desc: "We develop custom software applications tailored to specific business needs. We build mobile applications for various platforms and create web applications, websites and e-commerce platforms.",
    },
    {
      id: 2,
      header: "IT Consulting & Services",
      desc: "We provide expert advice on technology strategy, implementation and optimization. We also conduct technology assessments and audits to identify areas for improvement. We also offer support and maintenance for IT systems including network monitoring, security updates and troubleshooting.",
    },
    {
      id: 3,
      header: "Cybersecurity and Data Protection",
      desc: "We implement security measures to protect against cyber threats including firewall setup, intrusion detection and vulnerability assessments. We provide data encryption, access controls and compliance with data protection regulations.",
    },
    {
      id: 4,
      header: "Cloud Computing Services",
      desc: "We assist with cloud migration, managing cloud infrastructure and optimizing cloud resources. We offer services related to popular cloud platforms like AWS, Azure and Google Cloud.",
    },
    {
      id: 5,
      header: "Tax Assessment",
      desc: "We offer Property Survey and Tax Assessment services. We have specialized knowledge of local processes and unique jurisdictional procedures.",
    },
    {
      id: 6,
      header: "GIS Mapping",
      desc: "The GIS services offered by us allows the GIS data conversions  from different sources to be merged in one common format.",
    },
    {
      id: 7,
      header: "Data Management and Analytics",
      desc: "We set up Databases and also offer Data Warehousing and Data Analytics platforms. We develop business intelligence solutions and provide Data Analysis Services.",
    },
    {
      id: 8,
      header: "AI and Machine Learning Services",
      desc: "We Develop and Deploy AI powered applications and solutions. We also offer consulting on how AI and ML can benefit businesses.",
    },
    {
      id: 9,
      header: "Digitization ",
      desc: "We specialize in digitizing physical records, documents, and processes to propel your business into the digital era. Our comprehensive digitization solutions encompass document scanning, data capture, and archival services, ensuring seamless accessibility and security of your critical information. Whether it's converting large-format documents, preserving historical records, or implementing automated workflows, we have the expertise to streamline your operations and enhance data management. With a focus on accuracy and efficiency, we're committed to delivering digitization solutions that empower your organization to thrive in the digital landscape.",
    },
    {
      id: 10,
      header: "Digital Marketing",
      desc: "We specialize in crafting tailored digital marketing strategies that drive results. From search engine optimization (SEO) and pay-per-click advertising (PPC) to engaging content creation and strategic social media management, we offer a full suite of services designed to boost your online visibility and customer engagement. Our team of seasoned digital marketers stays at the forefront of industry trends, ensuring that your brand stays relevant and competitive in the digital landscape. Whether you're a startup or an established enterprise, we're committed to delivering personalized solutions that align with your business goals and drive measurable success in the online sphere.",
    },
  ];

  const myDivRef = useRef();
  const [scrollHeight, setScrollHeight] = useState("");  
  const [expandedItems, setExpandedItems] = useState(service_data.map(() => false));
  useEffect(() => {
    const scrollHeight = myDivRef.current.scrollHeight;
    setScrollHeight(scrollHeight);
    console.log("Scroll Height:", scrollHeight);
  }, []);

  const handleInputChange = (index) => {
    // debugger
    const updatedItems = [...expandedItems];
    updatedItems[index] = !updatedItems[index];
    setExpandedItems(updatedItems);
    console.log('expandedItems', expandedItems);
  };
  return (
    <div id="services" className="service_container">
      <h2 className="header-secondary">Our Services</h2>
      <p className="text-center service__head">
        We provide exclusive services for your business
      </p>
      <div className="services">
        {service_data.map((service, index) => (
          <div key={service?.id}  className={`service ${expandedItems[index] ? "full-width" : ""}`}>
            <div className="service__header">
              {/* <img
              src="https://inventive-it.com/wp-content/themes/inventive-it/assets/img/icons/application_development.svg"
              alt="client"
            /> */}
              <h3>{service?.header}</h3>
            </div>
            <div className="service_content">
              <div className="shorten-text">
                <span ref={myDivRef} className="text-left serivice_desc">{service?.desc}</span>
              </div>
              <span>
                {scrollHeight > 40 && (
                  <input
                    type="checkbox"
                    className="read-more-less-btn"
                    style={{ display: "inline-block" }}
                    value={expandedItems[index]}
                    onChange={() => handleInputChange(index)}
                  />
                )}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Service;
