/* eslint-disable no-unused-vars */
import React from "react";

import "./style.css";
import ContentWrapper from "../../components/contentWrapper/contentWrapper";

const PageNotFound = () => {
    return (
        <div className="pageNotFound">
            <ContentWrapper>
                <span className="bigText">404</span>
                <span className="smallText">Page not found!</span>
            </ContentWrapper>
        </div>
    );
};

export default PageNotFound;
