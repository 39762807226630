import { Paper, Button } from '@mui/material'


function Item({item})
{
    return (
        <Paper>
            <img src={item?.image} alt={item?.title} style={{
                width: '100vw',
                height: '100vh',
                objectFit:'cover'
            }} />
            <div className="title_btn_container" style={{
                    position: 'absolute',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '100%',
                    height: '100%',
                    cursor: 'pointer'
            }}>
            {/* <h2>{item?.title}</h2> */}

            {/* <Button className="CheckButton">
                Check it out!
            </Button> */}
            </div>
        </Paper>
    )
}

export default Item;