import React from 'react'
import AboutUs from '../../assets/img/aboutUs.jpeg'
import './About.css'

const About = () => {
  return (
    <div id='about' className='h-min'>  
    <div className='header-secondary'>Who We Are</div>  
    {/* <div className='header-secondary'>About Us</div>   */}
    <p className="text-center about-us__head">
          About Us
      </p>
    <div className='main'>
      <div className='left'>
        <img className='leftImg' src={AboutUs} alt="Img" />
      </div>
      <div className='right'>
      Soarstake is a trusted and dedicated partner in harnessing the power of technology to drive innovation and achieve business excellence. Our objective is to cater our customers with the best possible solutions available that are robust, scalable and cost effective. With a team of seasoned experts and a commitment to cutting-edge solutions, we specialize in delivering IT services that propel organizations forward. We believe in leveraging innovation to transform industries and empower our clients to thrive in a digital world.       
          {/* <ul className='mt-1'>
            <li>Point1</li>
            <li>Point1</li>
            <li>Point1</li>
            <li>Point1</li>
          </ul> */}
      </div>
    </div>
    </div>
  )
}

export default About
